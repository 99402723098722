@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.customerCommentSection {
  width: 100vw;

  @media (--viewportMedium) {
    width: none;
    padding: 0 30px;
  }
}

.root {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  min-height: 514px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sliderContainer {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.title {
  color: var(--colorBlueTextDark);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  line-height: normal;
  letter-spacing: 1px;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.contentTitle {
  color: var(--colorTextBlack);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 0;
  max-width: 350px;

  @media (--viewportMedium) {
    font-size: 24px;
    max-width: none;
  }
}

.customerComment {
  color: var(--colorTextBlack);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5em;
  margin-top: 38px;
  max-width: 340px;

  @media (--viewportMedium) {
    font-size: 20px;
    max-width: 836px;
  }
}

.customerCommentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.customerInfoContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 16px;
  gap: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 22px;
    margin-top: 30px;
  }
}

.customerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  @media (--viewportMedium) {
    align-items: flex-start;
    gap: 0;
  }
}

.customerName {
  color: var(--colorBlueButton);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 0;
}

.customerSince {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightRegular);
  line-height: normal;
  margin: 0 0 16px 0;
  font-style: italic;

  @media (--viewportMedium) {
    font-style: normal;
    margin: 0;
  }
}

.sliderContainer :global(.slick-arrow),
.sliderContainer :global(.slick-prev),
.sliderContainer :global(.slick-next) {
  color: var(--marketplaceColor) !important;
}

.sliderContainer :global(.slick-next) {
  position: absolute;
  left: inherit;
}

.imgContainer {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 6px solid var(--colorBlueButton);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(-180deg,
      var(--marketplaceColorLight) 0%,
      var(--marketplaceColor) 100%);
  color: var(--colorWhite);
}

:global(.slick-slide) .imgContainer img {
  border-radius: inherit;
  display: inline;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  min-height: 90px;
  min-width: 90px;
  object-fit: cover;
}

.sliderContainerCustomer > :global(.slick-next:before),
:global(.slick-prev:before) {
  color: var(--marketplaceColor);
  opacity: 1;
}

.quotationImage {
  margin-top: 20px;
  margin-bottom: -20px;
}