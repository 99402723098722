@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  width: 100%;
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 105px auto;
    max-width: 1056px;
  }

  @media (--viewportMaxMedium) {
    width: calc(100% - 32px);
    max-width: 100%;
  }
}

.title {
  line-height: 1.4;
  font-size: 40px;
  font-weight: var(--fontWeightBold);
  text-align: center;
  color: var(--colorTextBlack);
}

h5 {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  text-align: center;
  color: var(--colorTextBlack);
  margin-bottom: 6px;
  line-height: 1.1;
}

a {
  color: var(--colorBlueButton);
  margin-left: 4px;
}

.listDiv ul {
  list-style-type: decimal;
}

.listDiv ul > li {
  margin-left: 54px;
}

.mailAdressDiv ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
